<template>
  <sign-page
    title-text="发票校验可用次数"
    :request="request"
    table-size="small"
    :column-list="columnList"
    :title-menus="currentUserId === 1 ? [{key: 'add', label: '添加'}] : []"
    :form-parms-add="formParmsAdd"
    @dataUpdate="dataUpdate"
    :table-actions="[]"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
    <template v-slot:title>
      <div class="count-info">
        <div class="info-item">累计充值:<span>{{countData.total}}</span></div>
        <div class="info-item">已用次数:<span>{{countData.successNum + countData.doingNum}}</span></div>
        <div class="info-item">剩余次数:<span>{{countData.lastNum}}</span></div>
      </div>
    </template>
  </sign-page>
</template>

<script>
import {
  billRequest as request
} from '../../api'

export default {
  computed: {
    currentUserId () {
      return this.$store.getters.currentUserId
    },
    formParmsAdd: {
      get () {
        let data = [{
          label: '添加次数',
          key: 'times',
          type: 'input',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '添加次数',
          sort: true,
          field: 'addTimes'
        },
        {
          title: '添加时间',
          sort: true,
          field: 'createTime',
          // render: (h, rowData) => {
          //   return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
          // }
        }]
        return data
      }
    }
  },
  created() {
    this.loadCountData()
  },
  methods: {
    dataUpdate () {
      this.loadCountData()
    },
    async loadCountData () {
      this.countData = await request.checkTimesCount()
    },
    async loadData () {
      let data = await request.checkTimes()
      data.sort((a, b) => {
        return b.id - a.id
      })
      return data
    }
  },
  data () {
    return {
      countData: {},
      request: {
        get: this.loadData,
        add: request.addCheckTimes
      }
    }
  }
}
</script>

<style lang="less">
.count-info {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .info-item {
    padding: 0 10px;
    span {
      margin-left: 5px;
    }
  }
}
</style>